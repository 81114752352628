import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">The trailguide technology</h1>
    <h3 className="text-center text-lg">
  A look at the tech and processes used to ensure excellent quality
    </h3>
    <br />
    <Image src="/coding/programmerspet.jpg" mdxType="Image" />
    <br />
    <h4>{`Flexibility`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Our platform is designed in a way which makes it extremely flexible and you
  can create multiple types of guide apps and solutions on top of it.
    </div>
    <br />
    <h4>{`Development trajectory`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The normal development trajectory in a startup is a very fast initial
  development speed, followed by a slowdown due to added complexity, followed by
  a complete stop trying to add new features because the codebase can no longer
  be safely worked on. This is then followed by a rewrite of the whole codebase
  - if you are able to obtain more funding. Trailguide is not that company.
    </div>
    <br />
    <h4>{`Test-driven development (TDD)`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We follow the process of red-green-refactor test-driven development (TDD)
  which means we write a test for new functionality (red=fails), then the code
  to satisfy the test (green=works), then the code is cleaned up and made easier
  to understand.
    </div>
    <br />
    <h4>{`A healthy codebase`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We are always able to change and improve our code without being afraid of
  breaking anything. Our codebase is healthy and future development will not
  grind to a halt. It will accelerate. The combination of a well-documented
  codebase with static code analysis and an automated test harness not only
  shows us that the code works as expected, but it will tell us immediately if
  we break anything.
    </div>
    <br />
    <h4>{`Front-end tech`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Our app is developed as a web app which means that it will work on any device
  having a web browser (
  <Link to="/news/why-not-native-app" mdxType="Link">read more here</Link>). It also means that
  we have a single codebase which reduces cost tremendously and that it can be
  accessed immediately by scanning a QR code.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The app is written in a modern style of JavaScript, using React as our UI
  framework, and Tailwind CSS for easy and efficient layout and visual
  appearance.
    </div>
    <br />
    <h4>{`Back-end tech`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The server is also written in JavaScript and runs on Node with a Mongo
  database for data storage. The server currently runs on AWS and we use
  Cloudinary for efficient image storage and distribution.
    </div>
    <br />
    <h4>{`Digital map solutions`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We have designed and developed our own digital maps where data is sourced from
  OpenStreetMap, Kartverket (and similar services from Slovenia, Austria,
  Switzerland, Italy, France, and Spain) as well as NASA/METI/AIST, Japan
  Spacesystem and U.S/Japan ASTER Science Team. Our map server runs at Hertzner
  in Germany and needs about 2TB of storage space. Other maps from open
  solutions are also available to the end user.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      